import { useGetTasksQuery } from 'reduxToolkit/endpoints/clinicianProfileService/client';
import styles from './YourActions.module.scss';
import { useGetAccessToken } from 'utils/hooks/useGetAccessToken';
import { useGetAllSurveysQuery } from 'reduxToolkit/endpoints/checkInService/survey';
import { useGetAllPsychometricsQuery } from 'reduxToolkit/endpoints/checkInService/psychometric';
import { useGetCheckInStatusQuery } from 'reduxToolkit/endpoints/checkInService/checkIn';
import { useGetAttachedClinicianDetails } from 'utils/hooks/clinicianProfileService/getClientDetails';
import { Skeleton } from 'antd';
import TaskItem from 'components/TaskItem/TaskItem';
import { useMemo } from 'react';
import { ROUTES_LIST } from 'routes/constants';
import { generatePath } from 'react-router-dom';
import { IS_DEVELOPMENT } from 'utils/featureToggle';

const { CHECKIN, PENDING_ACTIONS } = ROUTES_LIST;
const POLLING_INTERVAL = 60 * 1000; // 1 minute

const YourActions = () => {
  const {
    userInfo: { clientRecordId }
  } = useGetAccessToken();
  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();

  const { data: clientTasks, isLoading: isClientTasksLoading } = useGetTasksQuery(
    { clientRecordId },
    {
      skip: !clientRecordId,
      pollingInterval: POLLING_INTERVAL
    }
  );
  const { data: psychometricList, isLoading: isPsychometricListLoading } = useGetAllPsychometricsQuery(undefined, {
    pollingInterval: POLLING_INTERVAL
  });
  const { data: surveyList, isLoading: isSurveyListLoading } = useGetAllSurveysQuery(undefined, {
    pollingInterval: POLLING_INTERVAL
  });

  const clinicianSlugUrlOrId =
    attachedClinicianDetail?.clinician?._id || attachedClinicianDetail?.clinician?.slugUrl || '';
  const { data: checkInStatus, isLoading: isCheckInStatusLoading } = useGetCheckInStatusQuery(
    { clinicianSlugUrlOrId },
    {
      skip: !clinicianSlugUrlOrId,
      pollingInterval: POLLING_INTERVAL
    }
  );

  const isLoading =
    isAttachedClinicianDetailLoading ||
    isClientTasksLoading ||
    isPsychometricListLoading ||
    isSurveyListLoading ||
    isCheckInStatusLoading;

  const {
    totalLength,
    // hasFailedPayment,
    hasTasks
  } = useMemo(() => {
    const clientTasksLength = clientTasks ? clientTasks.consentList.length + clientTasks.assessmentList.length : 0;
    const surveyListLength = surveyList ? surveyList.length : 0;
    const psychometricListLength = psychometricList ? psychometricList.length : 0;

    const totalLength = clientTasksLength + surveyListLength + psychometricListLength;
    const hasFailedPayment = clientTasks && clientTasks.failedPayments.length > 0;
    const hasTasks = totalLength > 0 || hasFailedPayment || (IS_DEVELOPMENT && checkInStatus);

    return { totalLength, hasFailedPayment, hasTasks };
  }, [checkInStatus, clientTasks, psychometricList, surveyList]);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Skeleton.Button block active />
      ) : hasTasks ? (
        <>
          {/* TODO: hasFailedPayment */}
          <div className={styles.title}>Your Actions</div>
          {totalLength > 0 && (
            <TaskItem
              className={styles.taskButton}
              name={
                <span>
                  You have{' '}
                  <strong>
                    {totalLength} outstanding request{totalLength !== 1 && 's'}
                  </strong>
                </span>
              }
              url={PENDING_ACTIONS}
            />
          )}
          {IS_DEVELOPMENT && checkInStatus && (
            <TaskItem
              className={styles.taskButton}
              name="View my journal (dev)"
              url={generatePath(CHECKIN, { clinicianSlugUrlOrId })}
            />
          )}
        </>
      ) : (
        <div className={styles.noTasks}>You have no outstanding tasks at this time.</div>
      )}
    </div>
  );
};

export default YourActions;

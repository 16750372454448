import { checkInServicesApiSlice } from 'reduxToolkit/services/checkInServicesApiSlice';

interface SurveyListItem {
  _id: string;
  title: string;
  isGPSurvey: boolean;
  totalQuestions: number;
  createdAt: string;
}

const surveyApiSlice = checkInServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSurveys: builder.query<SurveyListItem[], void>({
      query: () => ({
        url: '/client-records/me/surveys'
      }),
      providesTags: ['SurveyList']
    })
  })
});

export const { useGetAllSurveysQuery } = surveyApiSlice;

import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import NoAuthLayout from '../NoAuthLayout/NoAuthLayout';

import styles from './AuthLayout.module.scss';
import { verifyEmail } from 'utils/http/patient';
import { useGetAccessToken } from 'utils/hooks/useGetAccessToken';
import LoadingDots from 'components/LoadingDots/LoadingDots';

const AuthLayout = () => {
  const { user } = useAuth0();
  const { token } = useGetAccessToken();
  const [isVerifyLoading, setIsVerifyLoading] = useState(true);

  useEffect(() => {
    if (token && user) {
      if (user['https://tacklit.com/roles'].includes('clinician') && process.env.REACT_APP_CLINICIAN_DOMAIN) {
        window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
      }

      const checkSignUpIsNotClaim = !user['https://tacklit.com/isSignupClaimed'];
      const checkSignUpClaimFor = !(
        user['https://tacklit.com/signupClaimedFor'] &&
        user['https://tacklit.com/signupClaimedFor'].some((claimed: string) =>
          user['https://tacklit.com/attachedClinicians'].includes(claimed)
        )
      );

      if (checkSignUpIsNotClaim && checkSignUpClaimFor) {
        const fetchVerifyEmail = async (token: string) => {
          setIsVerifyLoading(true);
          await verifyEmail(token);
          setIsVerifyLoading(false);
        };

        fetchVerifyEmail(token);
      } else {
        setIsVerifyLoading(false);
      }
    }
  }, [token, user]);

  return isVerifyLoading ? (
    <div className={styles.loading}>
      <LoadingDots />
    </div>
  ) : (
    <Outlet />
  );
};

export default withAuthenticationRequired(AuthLayout, {
  returnTo: `${window.location.pathname}${window.location.search}`,
  onRedirecting: () => (
    <NoAuthLayout>
      <LoadingDots />
    </NoAuthLayout>
  )
});

import { useNavigate } from 'react-router-dom';
import styles from './TaskItem.module.scss';
import classNames from 'classnames';
import { ReactNode } from 'react';

interface TaskItemProps {
  className?: string;
  name: string | ReactNode;
  url?: string;
  isExternal?: boolean;
  onClick?: () => void;
}

const TaskItem = ({ className, name, url, isExternal, onClick }: TaskItemProps) => {
  const navigate = useNavigate();

  const handleTaskButtonClick = () => {
    if (onClick) {
      onClick();
    } else if (url) {
      if (isExternal) {
        window.location.href = url;
      } else {
        navigate(url);
      }
    }
  };

  return (
    <div className={classNames(styles.container, className)} onClick={handleTaskButtonClick}>
      <div className={styles.text}>{name}</div>
      <div className={`material-icons-outlined ${styles.arrowIcon}`}>
        {isExternal ? 'open_in_new' : 'arrow_forward'}
      </div>
    </div>
  );
};

export default TaskItem;

import { notification } from 'antd';
import { useState, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { postGetUpdateStripePaymentDetailsLink } from 'utils/http/billingService/stripe';
import { useGetAccessToken } from '../useGetAccessToken';

enum SavePaymentResult {
  Success = 'success',
  Fail = 'fail'
}
const savePaymentSearchParamKey = 'save-payment-detail-status';

export const useRedirectStripeUpdatePaymentDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { token } = useGetAccessToken();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const statusParam = searchParams.get(savePaymentSearchParamKey);

    if (statusParam) {
      switch (statusParam) {
        case SavePaymentResult.Success:
          notification.success({ message: 'Payment details updated successfully.' });
          break;

        case SavePaymentResult.Fail:
          notification.error({ message: 'Failed to update payment details.' });
          break;
      }

      searchParams.delete(savePaymentSearchParamKey);
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const redirectToStripeUpdatePaymentDetailsLink = useCallback(async () => {
    setIsLoading(true);

    const generateUrl = (value: string) =>
      `${window.location.origin}${window.location.pathname}?${savePaymentSearchParamKey}=${value}`;

    try {
      const response = await postGetUpdateStripePaymentDetailsLink({
        token,
        payload: {
          returnUrl: generateUrl(SavePaymentResult.Success),
          cancelUrl: generateUrl(SavePaymentResult.Fail)
        }
      });

      window.location.href = await response.text();
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      notification.error({ message: 'Something went wrong while trying to save payment details.' });
    }
  }, [token]);

  return { redirectToStripeUpdatePaymentDetailsLink, isLoading };
};

import Button, { ButtonVariant } from 'components/Button/Button';
import styles from './ContactSupport.module.scss';
import { useContext } from 'react';
import { EngageContext } from 'contexts/EngageContextProvider';
import classNames from 'classnames';

interface ContactSupportProps {
  className?: string;
}

const ContactSupport = ({ className }: ContactSupportProps) => {
  const {
    engageConfig: { contactUsText, contactUsUrl }
  } = useContext(EngageContext);

  return (
    contactUsUrl && (
      <div className={classNames(styles.container, className)}>
        <div className={styles.title}>Have a question?</div>
        {/* dangerouslySetInnerHTML? */}
        {contactUsText && <div className={styles.description}>{contactUsText}</div>}
        <Button
          className={styles.contactButton}
          variant={ButtonVariant.Outlined}
          onClick={() => (window.location.href = contactUsUrl)}
        >
          Get in touch with our support team
        </Button>
      </div>
    )
  );
};

export default ContactSupport;

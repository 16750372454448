import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { store } from './reduxToolkit/store';
import { defaultTheme } from '@ant-design/compatible';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import Auth0ProviderWithHistory from 'components/Auth/Auth0ProviderWithHistory';
import Routes from 'routes/Routes';
import { EngageContextProviderWithHelmetDefaults } from 'contexts/EngageContextProvider';

import 'i18n';

import * as serviceWorker from './serviceWorker';

import './antdConfig';
import './index.scss';

const SENTRY_HOST = process.env.REACT_APP_SENTRY_HOST;

const sentryBrowserTracing = new Integrations.BrowserTracing();
const sentryCaptureConsole = new CaptureConsole({ levels: ['error'] });

Sentry.init({
  dsn: SENTRY_HOST,
  integrations: [sentryBrowserTracing, sentryCaptureConsole],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <StrictMode>
    <Provider store={store}>
      <ConfigProvider theme={defaultTheme}>
        <EngageContextProviderWithHelmetDefaults>
          <BrowserRouter>
            <Auth0ProviderWithHistory>
              <Routes />
            </Auth0ProviderWithHistory>
          </BrowserRouter>
        </EngageContextProviderWithHelmetDefaults>
      </ConfigProvider>
    </Provider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

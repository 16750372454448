export const ROUTES_LIST = {
  PRACTITIONER: {
    LISTING: '/psychologists',
    LISTING_WITH_FILTER: '/psychologists/:filter',
    DETAILS: '/psychologists-profile/:clinicianId'
  },
  SIGNUP: {
    BASE: '/signup',
    CONFIRM_BOOKING: '/booking-confirm',
    WELCOME_BOOKING: '/welcome'
  },

  INVOICE: {
    VIEW: '/invoice/:invoiceId'
  },
  REPORT: {
    VIEW: '/report/:reportId'
  },

  CONSENT: {
    BASE: '/consent',
    DETAILS: '/consent/:consentFormId'
  },
  SURVEY: {
    BASE: '/surveys',
    DETAILS: '/surveys/:surveyId'
  },
  ONBOARDING: {
    BASE: '/onboarding'
  },
  PSYCHOMETRIC: {
    BASE: '/psychometrics',
    DETAILS: '/:clinicianSlugUrlOrId/assessment/:assessmentId'
  },
  CHECKIN: '/:clinicianSlugUrlOrId/checkin',

  LOGIN: '/login',
  AVATAR: '/avatar',
  HOME: '/',
  PENDING_ACTIONS: '/pending-actions',
  UPDATE_PROFILE: '/profile',
  APPOINTMENT_OVERVIEW: '/appointment/overview',
  REFERRAL: '/referral',
  MY_PSYCHOLOGIST: '/my-psychologist'
} as const;

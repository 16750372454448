import { createContext, ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { defaultEngageConfig, EngageConfig, useEngageConfig } from 'utils/hooks/useEngageConfig';

export const EngageContext = createContext<{
  engageConfig: EngageConfig;
}>({ engageConfig: defaultEngageConfig });

export const EngageContextProviderWithHelmetDefaults = ({ children }: { children: ReactNode }) => {
  const engageConfig = useEngageConfig();

  return (
    <EngageContext.Provider
      value={{
        engageConfig
      }}
    >
      <HelmetProvider>{children}</HelmetProvider>
    </EngageContext.Provider>
  );
};

import { checkInServicesApiSlice } from 'reduxToolkit/services/checkInServicesApiSlice';

interface PsychometricListItem {
  _id: string;
  clinicalAssessmentIds: string[];
  clinicianAuth0Id: string;
}

const psychometricApiSlice = checkInServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPsychometrics: builder.query<PsychometricListItem[], void>({
      query: () => ({
        url: '/client-records/me/ad-hoc-assessments'
      }),
      providesTags: ['PsychometricList']
    })
  })
});

export const { useGetAllPsychometricsQuery } = psychometricApiSlice;

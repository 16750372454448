import { createContext, ReactNode, useEffect } from 'react';
import LoadingDots from 'components/LoadingDots/LoadingDots';
import NoAuthLayout from 'layouts/NoAuthLayout/NoAuthLayout';
import { useGetClientProfileDetails } from 'utils/hooks/clinicianProfileService/getClientDetails';
import { useNavigate } from 'react-router-dom';
import { PatientProfile } from 'interfaces/Client';

export const UserContext = createContext<{
  clientProfile?: PatientProfile;
}>({});

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  const { clientProfileData, isClientProfileLoading, isError } = useGetClientProfileDetails();

  useEffect(() => {
    if (isError) {
      navigate('/404');
    }
  }, [isError, navigate]);

  return isClientProfileLoading ? (
    <NoAuthLayout>
      <LoadingDots />
    </NoAuthLayout>
  ) : (
    <UserContext.Provider
      value={{
        clientProfile: clientProfileData
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

import { GetTokenSilentlyOptions } from '@auth0/auth0-react';

let getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<string | undefined>;

export const security = {
  getAccessTokenSilently: () =>
    getAccessTokenSilently({ authorizationParams: { audience: process.env.REACT_APP_API_AUDIENCE } }),
  setAccessTokenSilently: (func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string | undefined>) =>
    (getAccessTokenSilently = func)
};

import { useMemo } from 'react';
import { object, string, ObjectSchema, boolean } from 'yup';

export interface EngageConfig {
  metaTitlePrefix: string;
  contactUsText?: string;
  contactUsUrl?: string;

  useDarkLogo: boolean;

  allowBookNewAppointment: boolean;
  allowEditStripeCard: boolean;
}

export const defaultEngageConfig: EngageConfig = {
  metaTitlePrefix: '',

  useDarkLogo: false,

  allowBookNewAppointment: false,
  allowEditStripeCard: false
};

const engageConfigValidator: ObjectSchema<EngageConfig> = object({
  metaTitlePrefix: string().required(),
  contactUsText: string(),
  contactUsUrl: string(),

  useDarkLogo: boolean().required(),

  allowBookNewAppointment: boolean().required(),
  allowEditStripeCard: boolean().required()
});

export const useEngageConfig = () => {
  const engageConfig = useMemo(
    () => (engageConfigValidator.isValidSync(window.engageConfig) ? window.engageConfig : defaultEngageConfig),
    []
  );

  return engageConfig;
};

import tacklitLogo from 'assets/images/tacklit-logo-blue.svg';
import tacklitLogoWhite from 'assets/images/tacklit-logo-white.svg';
import styles from './TacklitFooter.module.scss';
import classNames from 'classnames';

interface TacklitFooterProps {
  className?: string;
  wrapperClassName?: string;
  darkFooter?: boolean;
  linkToTacklit?: boolean;
}

const TacklitFooter = ({ className, wrapperClassName, darkFooter, linkToTacklit }: TacklitFooterProps) => {
  return (
    <div className={classNames(styles.wrapper, wrapperClassName)}>
      <div className={classNames(darkFooter ? styles.darkContainer : styles.container, className)}>
        <img alt={'tacklit logo'} className={styles.tacklitLogo} src={darkFooter ? tacklitLogoWhite : tacklitLogo} />
        <div className={styles.copyrightSymbol}>©</div> Powered by
        {linkToTacklit ? (
          <>
            &nbsp;
            <a href="https://www.tacklit.com" target="_blank" rel="noreferrer">
              Tacklit
            </a>
          </>
        ) : (
          ' Tacklit'
        )}
      </div>
    </div>
  );
};

export default TacklitFooter;

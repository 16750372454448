import { Component, FunctionComponent } from 'react';

interface ComponentWithLayoutProps {
  component: typeof Component | FunctionComponent;
  layout: typeof Component | FunctionComponent;
}

const ComponentWithLayout = ({ component: Component, layout: Layout }: ComponentWithLayoutProps) => {
  return (
    <Layout>
      <Component />
    </Layout>
  );
};

export default ComponentWithLayout;

import { EngageContext } from 'contexts/EngageContextProvider';
import { ReactNode, useContext } from 'react';
import { Helmet } from 'react-helmet-async';

interface HelmetWrapperProps {
  title?: string;
  children?: ReactNode;
}

const HelmetWrapper = ({ title, children }: HelmetWrapperProps) => {
  const {
    engageConfig: { metaTitlePrefix }
  } = useContext(EngageContext);

  return (
    <>
      <Helmet>
        <title>
          {metaTitlePrefix}
          {title ? ` | ${title}` : ''}
        </title>
      </Helmet>
      {children}
    </>
  );
};

export default HelmetWrapper;

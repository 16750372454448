import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

export const useLogout = () => {
  const { logout: auth0Logout } = useAuth0();

  const logout = useCallback(() => {
    auth0Logout({ logoutParams: { returnTo: window.location.origin } });
  }, [auth0Logout]);

  return { logout };
};

import styles from './LoadingDots.module.scss';

const LoadingDots = () => (
  <div className={styles.loading}>
    <div className={styles.circle} />
    <div className={styles.circle} />
    <div className={styles.circle} />
    <div className={styles.circle} />
    <div className={styles.circle} />
  </div>
);

export default LoadingDots;

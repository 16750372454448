import classNames from 'classnames';

import styles from './ContentLayout.module.scss';
import { ReactNode } from 'react';

interface ContentLayoutProps {
  children: ReactNode;
  className?: string;
}

const ContentLayout = ({ children, className }: ContentLayoutProps) => {
  return <div className={classNames(styles.container, className)}>{children}</div>;
};

export default ContentLayout;

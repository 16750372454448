import ComponentWithLayout from 'components/ComponentWithLayout/ComponentWithLayout';
import NoAuthLayout from 'layouts/NoAuthLayout/NoAuthLayout';
import Forbidden403 from 'pages/403/403';
import NotFound404 from 'pages/404/404';
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import { useGetAccessToken } from 'utils/hooks/useGetAccessToken';
import { security } from 'utils/security';
import UserRoutes from './UserRoutes';

const Routes = () => {
  const { fetchAccessTokenAndInfo } = useGetAccessToken(true, true);
  security.setAccessTokenSilently(fetchAccessTokenAndInfo);

  return (
    <RouterRoutes>
      <Route path="/403" element={<ComponentWithLayout component={Forbidden403} layout={NoAuthLayout} />} />
      <Route path="/404" element={<ComponentWithLayout component={NotFound404} layout={NoAuthLayout} />} />
      <Route path="/*" element={<UserRoutes />} />
    </RouterRoutes>
  );
};

export default Routes;

import styles from './HomePage.module.scss';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import { useAuth0 } from '@auth0/auth0-react';
import ClientHeader from 'components/ClientHeader/ClientHeader';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import WelcomeMessage from './components/WelcomeMessage/WelcomeMessage';
import YourActions from './components/YourActions/YourActions';
import Suggestions from './components/Suggestions/Suggestions';
import ContactSupport from './components/ContactSupport/ContactSupport';

const HomePage = () => {
  const { user } = useAuth0();

  return (
    <HelmetWrapper title={`Welcome ${user ? user.name : ''}`}>
      <div className={styles.container}>
        <div>
          <ContentLayout className={styles.content}>
            <ClientHeader withMenu />
            <div className={styles.card}>
              <WelcomeMessage />
              {/* TOTO: 5D appointment */}
              <div className={styles.flexContent}>
                {/* top/right */}
                <div className={styles.topRightColumn}>
                  <YourActions />
                  <ContactSupport className={styles.contactSupport} />
                </div>
                {/* bottom/left */}
                <div className={styles.bottomLeftColumn}>
                  <Suggestions />
                  <ContactSupport className={styles.contactSupport} />
                </div>
              </div>
            </div>
          </ContentLayout>
        </div>
        <TacklitFooter wrapperClassName={styles.footer} darkFooter />
      </div>
    </HelmetWrapper>
  );
};

export default HomePage;

import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider, AppState } from '@auth0/auth0-react';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const checkHasAccessToWindows = () => {
  try {
    // This is to bypass issues with Auth0
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const testRef = window.sessionStorage;
    return true;
  } catch (_) {
    return false;
  }
};

interface Auth0ProviderWithHistoryProps {
  children?: ReactNode;
}

const Auth0ProviderWithHistory = ({ children }: Auth0ProviderWithHistoryProps) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (checkHasAccessToWindows()) {
    return (
      <Auth0Provider
        domain={domain!}
        clientId={clientId!}
        onRedirectCallback={onRedirectCallback}
        authorizationParams={{
          redirectUri: window.location.origin,
          audience: process.env.REACT_APP_API_AUDIENCE
        }}
        useRefreshTokens
      >
        {children}
      </Auth0Provider>
    );
  } else {
    return <>{children}</>;
  }
};

export default Auth0ProviderWithHistory;

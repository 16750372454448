import { checkInServicesApiSlice } from '../../services/checkInServicesApiSlice';

const checkInApiSlice = checkInServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCheckInStatus: builder.query<boolean, { clinicianSlugUrlOrId: string }>({
      query: ({ clinicianSlugUrlOrId }) => ({
        url: `client-check-in-status?clinicianId=${clinicianSlugUrlOrId}`
      }),
      transformResponse: (response: { status?: boolean }) => !!response.status,
      providesTags: ['CheckInStatus']
    })
  })
});

export const { useGetCheckInStatusQuery } = checkInApiSlice;

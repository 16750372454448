import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import { useContext, useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';
import HeaderMenu from './components/HeaderMenu/HeaderMenu';
import styles from './ClientHeader.module.scss';
import { Skeleton } from 'antd';
import { useCustomLogo } from 'utils/hooks/useCustomLogo';
import { useGetAccessToken } from 'utils/hooks/useGetAccessToken';
import { useLogout } from 'utils/hooks/logout';
import { ROUTES_LIST } from 'routes/constants';
import { EngageContext } from 'contexts/EngageContextProvider';
import { useGetCheckInStatusQuery } from 'reduxToolkit/endpoints/checkInService/checkIn';
import {
  useGetAttachedClinicianDetails,
  useGetClientProfileDetails
} from 'utils/hooks/clinicianProfileService/getClientDetails';
import { useRedirectStripeUpdatePaymentDetails } from 'utils/hooks/billingService/useRedirectStripeUpdatePaymentDetails';
import LoadingDots from 'components/LoadingDots/LoadingDots';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import { IS_DEVELOPMENT } from 'utils/featureToggle';

const { APPOINTMENT_OVERVIEW, CHECKIN, HOME, PENDING_ACTIONS, PRACTITIONER, REFERRAL, UPDATE_PROFILE } = ROUTES_LIST;

interface ClientHeaderProps {
  withMenu?: boolean;
  noPadding?: boolean;
  darkLogo?: boolean;
}

const ClientHeader = ({ withMenu, noPadding, darkLogo }: ClientHeaderProps) => {
  const { user, isAuthenticated } = useAuth0();
  const { logout } = useLogout();
  const {
    userInfo: { initials },
    isQueryParamToken
  } = useGetAccessToken(true);
  const { engageConfig } = useContext(EngageContext);

  const {
    darkLogo: darkCustomLogo,
    lightLogo: lightCustomLogo,
    logoAlt,
    isCustomLogo,
    isLogoLoading
  } = useCustomLogo();
  const { attachedClinicianDetail } = useGetAttachedClinicianDetails();
  const { clientProfileData } = useGetClientProfileDetails();
  const { redirectToStripeUpdatePaymentDetailsLink, isLoading: isStripeLoading } =
    useRedirectStripeUpdatePaymentDetails();

  const clinicianSlugUrlOrId =
    attachedClinicianDetail?.clinician?._id || attachedClinicianDetail?.clinician?.slugUrl || '';

  const { data: checkInStatus } = useGetCheckInStatusQuery(
    { clinicianSlugUrlOrId },
    {
      skip: !clinicianSlugUrlOrId,
      pollingInterval: 5 * 60 * 1000 // 5 minutes
    }
  );

  const menuItems = useMemo(
    () => [
      { name: 'Home', url: HOME, isExternal: false },
      { name: 'Actions', url: PENDING_ACTIONS, isExternal: false },
      { name: 'Edit profile', url: UPDATE_PROFILE, isExternal: false },
      ...(IS_DEVELOPMENT && checkInStatus && clinicianSlugUrlOrId
        ? [{ name: 'View my journal (dev)', url: generatePath(CHECKIN, { clinicianSlugUrlOrId }), isExternal: false }]
        : []),
      { name: 'Manage appointments', url: APPOINTMENT_OVERVIEW, isExternal: false },
      ...(IS_DEVELOPMENT ? [{ name: 'Referral details (dev)', url: REFERRAL, isExternal: false }] : []),
      ...(engageConfig.allowEditStripeCard
        ? [{ name: 'Payment details', isExternal: true, onClick: redirectToStripeUpdatePaymentDetailsLink }]
        : []),
      ...(engageConfig.contactUsUrl ? [{ name: 'Contact team', url: engageConfig.contactUsUrl, isExternal: true }] : [])
    ],
    [
      checkInStatus,
      clinicianSlugUrlOrId,
      engageConfig.allowEditStripeCard,
      engageConfig.contactUsUrl,
      redirectToStripeUpdatePaymentDetailsLink
    ]
  );

  const shouldUseDarkLogo = darkLogo ?? engageConfig.useDarkLogo;

  const bookNewAppointmentUrl = attachedClinicianDetail?.clinician
    ? generatePath(PRACTITIONER.DETAILS, {
        clinicianId: attachedClinicianDetail.clinician._id
      })
    : PRACTITIONER.LISTING;

  return (
    <>
      {isStripeLoading && (
        <div className={styles.loading}>
          <LoadingDots />
        </div>
      )}
      <div className={classNames(styles.container, noPadding && styles.noPadding)}>
        <div>
          {isLogoLoading ? (
            <div className={styles.logoLoading}>
              <Skeleton.Input active className={styles.logoLoadingImg} />
            </div>
          ) : (
            <Link to={'/'}>
              <img
                className={isCustomLogo ? styles.customLogoImage : styles.logoImage}
                src={shouldUseDarkLogo ? darkCustomLogo : lightCustomLogo}
                alt={logoAlt}
              />
            </Link>
          )}
        </div>
        <div className={styles.profileWrapper}>
          <div className={styles.profileBox}>
            <div className={styles.profile}>
              <ClientProfileAvatar
                avatarUrl={clientProfileData?.avatar || user?.picture}
                initialsName={initials}
                tokenUser={isQueryParamToken}
              />
            </div>
            {!isQueryParamToken && withMenu && isAuthenticated && (
              <div className={styles.menuWrapper}>
                <HeaderMenu menuItems={menuItems} bookNewAppointmentUrl={bookNewAppointmentUrl} />
              </div>
            )}
          </div>
          {!isQueryParamToken && isAuthenticated && !withMenu && (
            <div className={styles.logOutBtn} onClick={logout}>
              Log out <i className={`material-icons ${styles.icon}`}>exit_to_app</i>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ClientHeader;

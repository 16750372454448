import { PatientProfile } from 'interfaces/Client';
import { clinicianProfileServicesApiSlice } from '../../services/clinicianProfileServicesApiSlice';
import { GetAttachedClinicianDetails } from 'interfaces/Clinician';
import dayjs from 'utils/dayjsExtended';

interface SurveyItem {
  _id: string;
  createdAt: string;
  title: string;
  totalQuestions: number;
}

interface FailedPaymentItem {
  _id: string;
  amount: number;
  systemRetryCount: number;
  accountId: string;
  clientRecordId: string;
  status: string;
  type: string;
  method: string;
  appointmentId: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export interface TaskListInterface {
  consentList: { _id: string; name: string }[];
  onboardingQuestionCount: number;
  assessmentList: SurveyItem[];
  failedPayments: FailedPaymentItem[];
}

const clientRecordsApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get client record details
    getClientDetails: builder.query<PatientProfile, null>({
      query: () => ({
        url: '/client-records/me'
      }),
      transformResponse: (response: PatientProfile) => {
        return {
          ...response,
          ...(response.medicare && {
            medicare: {
              ...response.medicare,
              dateOfBirth: dayjs(response.medicare?.dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY')
            }
          })
        };
      },
      providesTags: ['ClientRecordsMe']
    }),

    // Get attached clinician details
    getAttachedClinicianDetails: builder.query<GetAttachedClinicianDetails, null>({
      query: () => ({
        url: '/client-records/me:getAttachedAccountAndClinician'
      }),
      providesTags: ['AttachedClinicianDetails']
    }),

    // Get tasks
    getTasks: builder.query<TaskListInterface, { clientRecordId: string }>({
      query: ({ clientRecordId }) => ({
        url: `/client-records/${clientRecordId}/tasks`
      }),
      transformResponse: (response: any) => {
        return {
          ...response,
          onboardingQuestionCount: response.onboarding,
          isTaskCompleted:
            !response.consent && response.assessmentList.length === 0 && response.failedPayments.length === 0
        } as TaskListInterface;
      },
      keepUnusedDataFor: 5,
      providesTags: ['ClientRecordTasks']
    })
  })
});

export const { useGetClientDetailsQuery, useGetAttachedClinicianDetailsQuery, useGetTasksQuery } =
  clientRecordsApiSlice;

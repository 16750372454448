import { combineReducers } from 'redux';
import { clinicianProfileServicesApiSlice } from './services/clinicianProfileServicesApiSlice';
import { checkInServicesApiSlice } from './services/checkInServicesApiSlice';

const rootReducer = combineReducers({
  // add reducers here
  [checkInServicesApiSlice.reducerPath]: checkInServicesApiSlice.reducer,
  [clinicianProfileServicesApiSlice.reducerPath]: clinicianProfileServicesApiSlice.reducer
});

export default rootReducer;

import { UserContextProvider } from 'contexts/UserContextProvider';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import HomePage from 'pages/HomePage/HomePage';
import { Route, Routes } from 'react-router-dom';

const UserRoutes = () => {
  return (
    <UserContextProvider>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/" element={<HomePage />} />
        </Route>
      </Routes>
    </UserContextProvider>
  );
};

export default UserRoutes;
